(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name neo.services.auth.factory:NewPassword
   *
   * @description
   *
   */
  angular
  .module('neo.services.auth')
  .factory('NewPassword', NewPassword);

  function NewPassword($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/auth/newPassword', null, {
      update: {
        method: 'PUT'
      }
    });
  }
}());
